import { useRouter } from 'next/router'
import useSWR from 'swr'

import { StrapiCountryResp } from '../../utils/types/strapi'
import { getCountryDefaultSpecialist } from '../../utils/strapi'
import { strapiFetcher } from '../../utils/fetcher'
import { getCountryAndLocaleStrings } from '../../utils/locales'

const useDefaultProductSpecialist = () => {
  const { locale: countryAndLocale } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const urlParam = '/countries?populate[defaultProductSpecialist][populate][0]=profilePicture'
  const { data, error } = useSWR<StrapiCountryResp>(`${process.env.NEXT_PUBLIC_CMS_BASE}/api${urlParam}`, strapiFetcher)

  if (error) {
    console.error(error)
  }

  const specialist = getCountryDefaultSpecialist(data?.data || [], country)

  return specialist
}

export default useDefaultProductSpecialist
