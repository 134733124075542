import { useRouter } from 'next/router'
import useSWR from 'swr'

import { filterProductSpecialistsByCountry } from '../../utils/strapi'
import { StrapiProductCategory, StrapiProductSpecialist, StrapiProductSpecialistResp } from '../../utils/types/strapi'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { strapiFetcher } from '../../utils/fetcher'
import { Catalog } from '../../utils/types/Product'
import useDefaultProductSpecialist from './useDefaultProductSpecialist'

const useProductSpecialistByCategory = (categories: Catalog[] | undefined) => {
  const urlParam = '/product-specialists?populate=productCategories,profilePicture,countries'
  const { data, error } = useSWR<StrapiProductSpecialistResp>(`${process.env.NEXT_PUBLIC_CMS_BASE}/api${urlParam}`, strapiFetcher)

  if (error) {
    console.error(error)
  }

  const { locale: countryAndLocale } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const filteredByCountry = filterProductSpecialistsByCountry(data?.data || [], country)

  // Match category with correct specialist
  let matchedSpecialist = useDefaultProductSpecialist() // Fallback
  categories?.forEach((cat) => filteredByCountry?.forEach(
    (specialist: StrapiProductSpecialist) => {
      const hasCategoryMatch = specialist?.attributes.productCategories.data
        .some((speCat: StrapiProductCategory) => speCat.attributes.code === cat['catalog.code'])

      if (hasCategoryMatch) {
        matchedSpecialist = specialist
      }
    },
  ))

  return matchedSpecialist
}

export default useProductSpecialistByCategory
